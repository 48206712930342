(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-intl"), require("react-dom"), require("react-modal"), require("react-router-dom"), require("luxon"), require("highcharts"), require("lodash"), require("country-list"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-intl", "react-dom", "react-modal", "react-router-dom", "luxon", "highcharts", "lodash", "country-list"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("react-intl"), require("react-dom"), require("react-modal"), require("react-router-dom"), require("luxon"), require("highcharts"), require("lodash"), require("country-list")) : factory(root["react"], root["react-intl"], root["react-dom"], root["react-modal"], root["react-router-dom"], root["luxon"], root["highcharts"], root["_"], root["country-list"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__39155__, __WEBPACK_EXTERNAL_MODULE__30993__, __WEBPACK_EXTERNAL_MODULE__69514__, __WEBPACK_EXTERNAL_MODULE__22215__, __WEBPACK_EXTERNAL_MODULE__4442__, __WEBPACK_EXTERNAL_MODULE__65796__, __WEBPACK_EXTERNAL_MODULE__59713__, __WEBPACK_EXTERNAL_MODULE__16252__, __WEBPACK_EXTERNAL_MODULE__97257__) => {
return 